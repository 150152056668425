Vue.component('cfour-crossselling-widget', {
    delimiters: ['${', '}'],
    props: {
        template: {type: String, default: '#vue-cfour-crossselling-widget'},
        itemsPerPage: {type: Number, default: 4},
        storeSpecialId: {type: String},
        sorting: {
            default: "givenids"
        },
        type: {
            default: ""
        }
    },

   data() {
       return {
           items: [],
           _storeSpecialId: '',
           _itemsPerPage: 0
       }
   },
   created() {
        this.$options.template = this.template;
        this._itemsPerPage = parseInt(this.itemsPerPage);
        this._storeSpecialId = this.storeSpecialId;
        this.getItems();
   },
   methods: {
        getItems() {
            const me = this;
            $.ajax({
                'url': '/rest/crossselling/'+this._storeSpecialId,
                'method': 'post',
                'data': {
                    "crosssellingId": this._storeSpecialId,
                    "type": this.type,
                    "sorting": this.sorting
                }
            }).done((data) => {
                me.items = data;
                me.initializeCarousel();
            });
        },

       initializeCarousel()
       {
           const self = this;

           $(this.$refs.carouselContainer).owlCarousel({
               autoHeight       : true,
               dots             : true,
               items            : self.itemsPerPage,
               responsive: {
                   0:   { items: 1 },
                   576: { items: self.itemsPerPage > 1 ? 2 : 1 },
                   768: { items: self.itemsPerPage > 3 ? 3 : self.itemsPerPage },
                   992: { items: self.itemsPerPage }
               },
               lazyLoad         : false,
               loop             : false,
               margin           : 30,
               mouseDrag        : true,
               nav              : true,
               navClass         : [
                   "owl-single-item-nav left carousel-control list-control-special",
                   "owl-single-item-nav right carousel-control list-control-special"
               ],
               navContainerClass: "",
               navText          : [
                   "<i class=\"owl-single-item-control fa fa-chevron-left\" aria-hidden=\"true\"></i>",
                   "<i class=\"owl-single-item-control fa fa-chevron-right\" aria-hidden=\"true\"></i>"
               ],
               smartSpeed       : 350,
               onChanged: property =>
               {
                   const begin = property.item.index;
                   const end = begin + property.page.size;

                   for (let i = begin; i < end; i++)
                   {
                       const childComponent = self.$children[i];

                       if (childComponent && childComponent.loadFirstImage)
                       {
                           childComponent.loadFirstImage();
                       }
                   }
               }
           });
       }
   }
});
